import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import Header from '../../components/header'
import Columns from '../../components/columns'
import Sidebar from '../../components/sidebar'
import AboutNav from '../../components/about-nav'

const image = require('../../images/dt_cropped.png')

const DavidTempletonPage = () => (
  <Layout>
    <Helmet
      title={'David Templeton, Registered Financial Consultant'}
      meta={[
        {
          name: 'description',
          content:
            // tslint:disable-next-line:max-line-length
            'David C. Templeton, RFC, founder and president of Adage Financial Consultants, Inc., has been in the financial services industry for over 30 years. He specializes in providing both safety and opportunity “with the same dollar at the same time.”',
        },
        {
          name: 'keywords',
          content:
            // tslint:disable-next-line:max-line-length
            'Adage Financial Consultants, David Templeton, Little Rock Arkansas, Financial Advisor, Financial Planner, 401k, Safe Money, Annuity',
        },
      ]}
    />
    <Header
      headline={'David Templeton, RFC'}
      image={image}
      copy={'Founder and president of Adage Financial Consultants, Inc.'}
    />
    <Columns background>
      <Sidebar>
        <AboutNav />
      </Sidebar>
      <div className="marketing__column">
        <p>
          David C. Templeton, RFC, founder and president of Adage Financial
          Consultants, Inc., has been in the financial services industry for
          over 30 years. He specializes in providing both safety and opportunity
          “with the same dollar at the same time.” David is a passionate
          advocate of strategies that help his clients (many of whom have been
          with him for decades) protect their principal while participating in
          market opportunities. David knows that to be trusted with someone’s
          financial future is a serious matter and one that deserves his
          careful, personal attention. That’s why in 1989 he left a lucrative
          position at one of the nation’s largest financial services firms to
          strike out on his own and provide client service his way. “After a few
          years of looking at a relationship from the firm’s perspective, I
          wanted to concentrate on serving my clients by expanding the solutions
          and services I could offer,” he says. “I didn’t want to be tied down
          with only being able to represent the products and services that the
          firm I was working with offered” David’s independence has served him,
          and his clients, very well.
        </p>
        <p>
          After watching clients’ portfolios rise and fall on the stock market
          rollercoaster, David searched for solutions that actually protected a
          client’s principal. “I was there when new technologies helped the
          financial services industries evolve. At that time I was actively
          searching for a way to help clients preserve the wealth they had
          worked so hard to accumulate.” David says when the market tanks his
          phone doesn’t ring. “I never get calls from my clients complaining
          about the market because my clients are protected from the
          turbulence,” he says. “I tell people I sell sleep insurance,” he adds.
          “But I have to sleep at night, too. That’s why I carefully research
          and make sure I fully understand everything we have access to at AFC.”
          A New Orleans native, David lives in Maumelle, Arkansas, where his
          passions are the Razorbacks, LSU and Saints football, Cajun cooking
          and spending as much time as possible outdoors. “I never pass up an
          opportunity to be in the outdoors, whether it be hunting, fishing or
          just relaxing at one of the beautiful lakes in Arkansas,” he says. His
          son, Drew, also grew up with a love of adventure. His is currently a
          reigning professional paintball champion, as well as a marketing
          specialist.
        </p>
      </div>
    </Columns>
  </Layout>
)

export default DavidTempletonPage
