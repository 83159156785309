import React from 'react'
import { Link } from 'gatsby'

const AboutNav = () => (
  <nav>
    <ul>
      <li>
        <Link activeClassName="sidebar--active" to="/about">
          About Adage Financial
        </Link>
      </li>
      <li>
        <Link activeClassName="sidebar--active" to="/about/david-templeton">
          David Templeton, RFC
        </Link>
      </li>
    </ul>
    <div className="sidebar__copy">
      <p>
        If you have questions about your financial future, we're here to help
        and welcome the opportunity to work with you to craft a financial plan
        that is as unique as you are.
      </p>
      <Link to="/contact">
        <button>Contact Us</button>
      </Link>
    </div>
  </nav>
)

export default AboutNav
